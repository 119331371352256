import Elegance_2Bedroom1 from '../../Media/Elegance/Elegance_2 Bedroom (1).webp'
import Elegance_2Bedroom2 from '../../Media/Elegance/Elegance_2 Bedroom (2).webp'
import Elegance_2Bedroom3 from '../../Media/Elegance/Elegance_2 Bedroom (3).webp'
import Elegance_2Bedroom4 from '../../Media/Elegance/Elegance_2 Bedroom (4).webp'
import Elegance_2Bedroom5 from '../../Media/Elegance/Elegance_2 Bedroom (5).webp'



const Elegance_2Bedroomimages =[
Elegance_2Bedroom1,Elegance_2Bedroom2,Elegance_2Bedroom3,Elegance_2Bedroom4,Elegance_2Bedroom5
]

export default Elegance_2Bedroomimages;
