import Altuera_1Bedroom1 from '../../Media/Altuera/Altuera _1 Bedroom (1).webp'
import Altuera_1Bedroom2 from '../../Media/Altuera/Altuera _1 Bedroom (2).webp'
import Altuera_1Bedroom3 from '../../Media/Altuera/Altuera _1 Bedroom (3).webp'
import Altuera_1Bedroom4 from '../../Media/Altuera/Altuera _1 Bedroom (4).webp'




const Altuera_1Bedroomimages =[
Altuera_1Bedroom1,Altuera_1Bedroom2,Altuera_1Bedroom3,Altuera_1Bedroom4
]

export default Altuera_1Bedroomimages;
