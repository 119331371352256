import React from "react";
import "./home.scss";
import Navbar from "../Section/Header/navbar";
import Banner from "../Section/Banner/banner";
import About from "../Section/About/about";
import Promo from "../Section/Promo/promo";
import AboutProduk from "../Section/AboutProduk/aboutproduk";
import Produk from "../Section/Produk/produk";
import Lokasi from "../Section/Lokasi/lokasi";
import Footer from "../Section/Footer/footer";
import tombol from "../Media/tombol.webp";

const home = () => {
  const fungsiganteng = () => {
    const whatsappLink =
      "https://api.whatsapp.com/send/?phone=6281296630318&text=Halo%20Twedy,%20Saya%20bisa%20minta%20detail%20Katalog,%20Simulasi,%20dan%20Promo%20terbaru%20Produk%20Southgate%20Resdience%20https://southgate-residence.com//&type=phone_number&app_absent=0";
    window.open(whatsappLink, "_blank");
  };
  return (
    <div className="home">
      <Navbar />
      <Banner />
      <About />
      <Promo />
      <AboutProduk />
      <Produk />
      <Lokasi />
      <Footer />
      <img
        onClick={fungsiganteng}
        src={tombol}
        className="tombolwa"
        alt="Hubungi Sekarang!"
      />
    </div>
  );
};

export default home;
