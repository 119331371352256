import React from "react";
import "./about.scss";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import aboutimg from "../../Media/Southgate.webp";
import {faCheckDouble} from "@fortawesome/free-solid-svg-icons";

const About = () => {
  const handleConsultationClick = () => {
    const whatsappLink =
      "https://api.whatsapp.com/send/?phone=6281296630318&text=Halo%20Twedy,%20Saya%20bisa%20minta%20detail%20Katalog,%20Simulasi,%20dan%20Promo%20terbaru%20Produk%20Southgate%20Resdience%20https://southgate-residence.com//&type=phone_number&app_absent=0";
    window.open(whatsappLink, "_blank");
  };

  return (
    <div className="about">
      <div id="about" className="container-about">
        <h1>Embrace Smart & Luxury Living of Your Dream Today</h1>
        <div className="container-desk">
          <div className="desk">
            Selamat Datang di Southgate Residence. Southgate Residence merupakan
            kompleks apartemen premium yang terletak di Jakarta Selatan,
            Indonesia. Southgate Residence menawarkan berbagai tipe unit,
            termasuk studio, satu kamar tidur, dan dua kamar tidur. Southgate
            Residence terhubung langsung dengan AEON Mall Tanjung Barat,
            memudahkan penghuni untuk mengakses berbagai kebutuhan sehari-hari
            dan hiburan.
          </div>
        </div>
        <div className="container-benefit">
          <div className="benefit-list">
            <div className="penawaranpoin">
              <FontAwesomeIcon icon={faCheckDouble} color="rgb(200, 169, 92)" />
              <span style={{color: "rgb(84, 84, 84)"}}>
                &nbsp;&nbsp;Seamless Connectivity
              </span>
            </div>
            <div className="penawaranpoin">
              <FontAwesomeIcon icon={faCheckDouble} color="rgb(200, 169, 92)" />
              <span style={{color: "rgb(84, 84, 84)"}}>
                &nbsp;&nbsp;Home For All Generations
              </span>
            </div>
            <div className="penawaranpoin">
              <FontAwesomeIcon icon={faCheckDouble} color="rgb(200, 169, 92)" />
              <span style={{color: "rgb(84, 84, 84)"}}>
                &nbsp;&nbsp;Nature's Daily Retreat
              </span>
            </div>
            <div className="penawaranpoin">
              <FontAwesomeIcon icon={faCheckDouble} color="rgb(200, 169, 92)" />
              <span style={{color: "rgb(84, 84, 84)"}}>
                &nbsp;&nbsp;Smart Living Ecosystem
              </span>
            </div>
          </div>
        </div>
        <div className="button">
          <div className="container-wa">
            <button
              className="buttonpenawaran"
              onClick={handleConsultationClick}>
              Learn More
            </button>
          </div>
        </div>
      </div>
      <div className="gambarabout">
        <img src={aboutimg} alt={aboutimg} />
      </div>
    </div>
  );
};

export default About;
