import Fasilitas1 from "../../Media/Fasilitas/Southgate (1).webp"
import Fasilitas2 from "../../Media/Fasilitas/Southgate (2).webp"
import Fasilitas3 from "../../Media/Fasilitas/Southgate (3).webp"
import Fasilitas4 from "../../Media/Fasilitas/Southgate (4).webp"
import Fasilitas5 from "../../Media/Fasilitas/Southgate (5).webp"
import Fasilitas6 from "../../Media/Fasilitas/Southgate (6).webp"
import Fasilitas7 from "../../Media/Fasilitas/Southgate (7).webp"
import Fasilitas8 from "../../Media/Fasilitas/Southgate (8).webp"
import Fasilitas9 from "../../Media/Fasilitas/Southgate (9).webp"
import Fasilitas10 from "../../Media/Fasilitas/Southgate (10).webp"
import Fasilitas11 from "../../Media/Fasilitas/Southgate (11).webp"

const Fasilitasimages =[
    
    Fasilitas1, Fasilitas2,Fasilitas3,Fasilitas4,Fasilitas5,Fasilitas6,Fasilitas7,Fasilitas8,Fasilitas9,Fasilitas10,Fasilitas11
    ]
    
    export default Fasilitasimages;