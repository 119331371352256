import Prime_2Bedroom1 from '../../Media/Prime/Prime_2 Bedroom (1).webp'
import Prime_2Bedroom2 from '../../Media/Prime/Prime_2 Bedroom (2).webp'
import Prime_2Bedroom3 from '../../Media/Prime/Prime_2 Bedroom (3).webp'
import Prime_2Bedroom4 from '../../Media/Prime/Prime_2 Bedroom (4).webp'
import Prime_2Bedroom5 from '../../Media/Prime/Prime_2 Bedroom (5).webp'



const Prime_2Bedroomimages =[
Prime_2Bedroom1,Prime_2Bedroom2,Prime_2Bedroom3,Prime_2Bedroom4,Prime_2Bedroom5
]

export default Prime_2Bedroomimages;
