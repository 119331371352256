import Altuera_2Bedroom1 from '../../Media/Altuera/Altuera_2 Bedroom (1).webp'
import Altuera_2Bedroom2 from '../../Media/Altuera/Altuera_2 Bedroom (2).webp'
import Altuera_2Bedroom3 from '../../Media/Altuera/Altuera_2 Bedroom (3).webp'
import Altuera_2Bedroom4 from '../../Media/Altuera/Altuera_2 Bedroom (4).webp'




const Altuera_2Bedroomimages =[
Altuera_2Bedroom1,Altuera_2Bedroom2,Altuera_2Bedroom3,Altuera_2Bedroom4
]

export default Altuera_2Bedroomimages;
