import React from "react";
import "./produk.scss";
import data from "../../Component/Card/data.json";
import Card from "../../Component/Card/card";
import Altuera_2Bedroomimages from "../../Media/Altuera/Altuera_2Bedroom";
import Altuera_Loftimages from "../../Media/Altuera/Altuera_Loft";
import Altuera_Studioimages from "../../Media/Altuera/Altuera_Studio";
import Elegance_2Bedroomimages from "../../Media/Elegance/Elegance_2Bedroom";
import Elegance_Maidimages from "../../Media/Elegance/Elegance_Maid";
import Prime_TwoBedroomimages from "../../Media/Prime/Prime_2Bedroom";
import Prime_3Bedroomimages from "../../Media/Prime/Prime_3Bedroom";
import Altuera_1Bedroomimages from "../../Media/Altuera/Altuera_1Bedroom";
import Prime_2Bedroomimages from "../../Media/Prime/Prime_2Bedroom";

const imageMap = {
  Altuera_1Bedroom: Altuera_1Bedroomimages,
  Altuera_2Bedroom: Altuera_2Bedroomimages,
  Altuera_Loft: Altuera_Loftimages,
  Altuera_Studio: Altuera_Studioimages,
  Elegance_2Bedroom: Elegance_2Bedroomimages,
  Elegance_Maid: Elegance_Maidimages,
  Prime_2Bedroom: Prime_2Bedroomimages,
  Prime_3Bedroom: Prime_3Bedroomimages,
  // Add other image arrays here if you have more sets
};

const produk = () => {
  return (
    <div id="produk" className="produk">
      <h1>Our Product</h1>

      <div className="cardhome-containerf">
        {data.cards.map((card, index) => {
          // Determine the logo dynamically
          // Determine the logo dynamically
          return (
            <Card
              key={index}
              carousel={imageMap[card.imagesKey]}
              title={card.title}
              subtitle={card.subtitle}
              price={card.price}
              subprice={card.subprice}
              details={card.details}
              whatsappLink={card.whatsappLink} // Pass the WhatsApp link
            />
          );
        })}
      </div>
    </div>
  );
};

export default produk;
