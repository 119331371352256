import Altuera_Loft1 from '../../Media/Altuera/Altuera_Loft (1).webp'
import Altuera_Loft2 from '../../Media/Altuera/Altuera_Loft (2).webp'
import Altuera_Loft3 from '../../Media/Altuera/Altuera_Loft (3).webp'
import Altuera_Loft4 from '../../Media/Altuera/Altuera_Loft (4).webp'
import Altuera_Loft5 from '../../Media/Altuera/Altuera_Loft (5).webp'
import Altuera_Loft6 from '../../Media/Altuera/Altuera_Loft (6).webp'




const Altuera_Loftimages =[
Altuera_Loft1,Altuera_Loft2,Altuera_Loft3,Altuera_Loft4,Altuera_Loft5,Altuera_Loft6
]

export default Altuera_Loftimages;
