import Elegance_Maid1 from '../../Media/Elegance/Elegance_2 Bedroom+maid (1).webp'
import Elegance_Maid2 from '../../Media/Elegance/Elegance_2 Bedroom+maid (2).webp'
import Elegance_Maid3 from '../../Media/Elegance/Elegance_2 Bedroom+maid (3).webp'
import Elegance_Maid4 from '../../Media/Elegance/Elegance_2 Bedroom+maid (4).webp'




const Elegance_Maidimages =[
Elegance_Maid1,Elegance_Maid2,Elegance_Maid3,Elegance_Maid4
]

export default Elegance_Maidimages;
