import React from "react";
import "./lokasi.scss";
import mapsimage from "../../Media/Maps_Desktop.webp";
import mobilemaps from "../../Media/Maps_Mobile.webp";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
  faBuilding,
  faCartShopping,
  faGraduationCap,
  faHouse,
  faMapPin,
  faShoppingBag,
} from "@fortawesome/free-solid-svg-icons";
import {FaShoppingBag} from "react-icons/fa";

const lokasi = () => {
  return (
    <div id="lokasi" className="lokasi">
      <div className="judul">Location</div>
      <div className="maps-lokasi">
        <img
          id="location"
          className="img-lokasi-dekstop"
          src={mapsimage}
          alt="maps"
        />
        <img
          id="lokasi"
          className="img-lokasi-mobile"
          src={mobilemaps}
          alt="maps"
        />
      </div>
      <div id="surroundingarea" className="benefit-container">
        <div className="benefit-point">
          <div className="benefit-title">
            <FontAwesomeIcon icon={faGraduationCap} color="#C88C39" />{" "}
            &nbsp;Education
          </div>
          <div className="benefit">
            <div className="point">
              <FontAwesomeIcon icon={faMapPin} color="#7b322c" />
              &nbsp;&nbsp;Jakarta Intercultural School
              <br />
              <FontAwesomeIcon icon={faMapPin} color="#7b322c" />
              &nbsp;&nbsp;ACG School Jakarta
              <br />
              <FontAwesomeIcon icon={faMapPin} color="#7b322c" />
              &nbsp;&nbsp;Highscope Indonesia School
              <br />
              <FontAwesomeIcon icon={faMapPin} color="#7b322c" />
              &nbsp;&nbsp;Indonesia University
            </div>
          </div>
        </div>
        <div className="benefit-point">
          <div className="benefit-title">
            <FontAwesomeIcon icon={faHouse} color="#C88C39" /> &nbsp;Health Care
          </div>
          <div className="benefit">
            <div className="point">
              <FontAwesomeIcon icon={faMapPin} color="#7b322c" />
              &nbsp;&nbsp;RSUD Pasar Minggu
              <br />
              <FontAwesomeIcon icon={faMapPin} color="#7b322c" />
              &nbsp;&nbsp;RS Fatmawati
              <br />
              <FontAwesomeIcon icon={faMapPin} color="#7b322c" />
              &nbsp;&nbsp;Siloam Hospitals
              <br />
              <FontAwesomeIcon icon={faMapPin} color="#7b322c" />
              &nbsp;&nbsp;Cilandak Marine Hospital
            </div>
          </div>
        </div>
        <div className="benefit-point">
          <div className="benefit-title">
            <FontAwesomeIcon icon={faShoppingBag} color="#C88C39" />{" "}
            &nbsp;Shopping Centre
          </div>
          <div className="benefit">
            <div className="point">
              <FontAwesomeIcon icon={faMapPin} color="#7b322c" />
              &nbsp;&nbsp;AEON Mall
              <br />
              <FontAwesomeIcon icon={faMapPin} color="#7b322c" />
              &nbsp;&nbsp;Pejaten Village
              <br />
              <FontAwesomeIcon icon={faMapPin} color="#7b322c" />
              &nbsp;&nbsp;Cilandak Town Square
              <br />
              <FontAwesomeIcon icon={faMapPin} color="#7b322c" />
              &nbsp;&nbsp;Lotte Grosir
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default lokasi;
