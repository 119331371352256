import Prime_3Bedroom1 from '../../Media/Prime/Prime_3 Bedroom (1).webp'
import Prime_3Bedroom2 from '../../Media/Prime/Prime_3 Bedroom (2).webp'
import Prime_3Bedroom3 from '../../Media/Prime/Prime_3 Bedroom (3).webp'
import Prime_3Bedroom4 from '../../Media/Prime/Prime_3 Bedroom (4).webp'
import Prime_3Bedroom5 from '../../Media/Prime/Prime_3 Bedroom (5).webp'



const Prime_3Bedroomimages =[
Prime_3Bedroom1,Prime_3Bedroom2,Prime_3Bedroom3,Prime_3Bedroom4,Prime_3Bedroom5
]

export default Prime_3Bedroomimages;
