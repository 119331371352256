import React from "react";
import "./promo.scss";
import promoimg from "../../Media/Southgate_Hardsell.webp";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faMedal} from "@fortawesome/free-solid-svg-icons";

const Cherrywa = () => {
  const whatsappLink =
    "https://api.whatsapp.com/send/?phone=6281296630318&text=Halo%20Twedy,%20Saya%20bisa%20minta%20detail%20Promo%20terbaru%20Produk%20Southgate%20Resdience%20https://southgate-residence.com//&type=phone_number&app_absent=0";
  window.open(whatsappLink, "_blank");
};

const promo = () => {
  return (
    <div id="promo" className="promolagoon">
      <div className="background">
        <div className="container-promo">
          <div className="promo-gambar">
            <img src={promoimg} alt={promoimg} />
          </div>
          <div className="containercontent">
            <div className="judul">
              <h1>Promo Southgate</h1>
            </div>
            <div className="pointpenawaran">
              <div className="penawaranpoin">
                <FontAwesomeIcon icon={faMedal} />
                <span style={{color: "white"}}>&nbsp;&nbsp;Siap Huni</span>
              </div>
              <div className="penawaranpoin">
                <FontAwesomeIcon icon={faMedal} />
                <span style={{color: "white"}}>&nbsp;&nbsp;Subsidi DP</span>
              </div>
              <div className="penawaranpoin">
                <FontAwesomeIcon icon={faMedal} />
                <span style={{color: "white"}}>
                  &nbsp;&nbsp;Subsidi Biaya KPA
                </span>
              </div>

              <div className="penawaranpoin">
                <FontAwesomeIcon icon={faMedal} />
                <span style={{color: "white"}}>&nbsp;&nbsp;Free PPN</span>
              </div>
              <div className="penawaranpoin">
                <FontAwesomeIcon icon={faMedal} />
                <span style={{color: "white"}}>&nbsp;&nbsp;Free BPHTB</span>
              </div>
              <div className="penawaranpoin">
                <FontAwesomeIcon icon={faMedal} />
                <span style={{color: "white"}}>
                  &nbsp;&nbsp;Voucher Funiture
                </span>
              </div>
              <div className="penawaranpoin">
                <FontAwesomeIcon icon={faMedal} />
                <span style={{color: "white"}}>
                  &nbsp;&nbsp;Cicilan Start 5 Juta/Bulan
                </span>
              </div>
            </div>
            <div className="disclaimer">*Disclaimer</div>
            <button className="buttonpenawaran" onClick={Cherrywa}>
              Learn More
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default promo;
