import Altuera_Studio1 from '../../Media/Altuera/Altuera_Studio (1).webp'
import Altuera_Studio2 from '../../Media/Altuera/Altuera_Studio (2).webp'
import Altuera_Studio3 from '../../Media/Altuera/Altuera_Studio (3).webp'
import Altuera_Studio4 from '../../Media/Altuera/Altuera_Studio (4).webp'




const Altuera_Studioimages =[
Altuera_Studio1,Altuera_Studio2,Altuera_Studio3,Altuera_Studio4
]

export default Altuera_Studioimages;
