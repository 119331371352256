import React from "react";
import "./aboutproduk.scss";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCheckDouble} from "@fortawesome/free-solid-svg-icons";
import Slider from "react-slick";
import Fasilitasimages from "./Fasilitas";

const About = () => {
  const handleConsultationClick = () => {
    const whatsappLink =
      "https://api.whatsapp.com/send/?phone=6281296630318&text=Halo%20Twedy,%20Saya%20bisa%20minta%20detail%20Katalog,%20Simulasi,%20dan%20Promo%20terbaru%20Produk%20Southgate%20Resdience%20https://southgate-residence.com//&type=phone_number&app_absent=0";
    window.open(whatsappLink, "_blank");
  };
  const settings1 = {
    dots: true,
    arrows: true,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
  };
  return (
    <div className="about">
      <div className="containerabout">
        <div id="fasilitas" className="container-about">
          <h1>Live Life with Everything You Need Just Around the Corners</h1>
          <div className="container-desk">
            <div className="desk">
              Southgate Residence menawarkan Apartemen modern di lokasi terbaik,
              pilihan sempurna untuk kenyamanan dan investasi masa depan yang
              cerdas. Southgate Residence juga menawarkan berbagai fasilitas
              untuk meningkatkan kenyamanan penghuninya, seperti :
            </div>
          </div>
          <div className="fasilitas-desk">
            <div className="fasilitas1">
              <div className="penawaranpoin">
                <FontAwesomeIcon
                  icon={faCheckDouble}
                  color="rgb(200, 169, 92)"
                />
                <span style={{color: "rgb(84, 84, 84)"}}>
                  &nbsp;&nbsp;Direct Access to AEON Mall
                </span>
              </div>
              <div className="penawaranpoin">
                <FontAwesomeIcon
                  icon={faCheckDouble}
                  color="rgb(200, 169, 92)"
                />
                <span style={{color: "rgb(84, 84, 84)"}}>
                  &nbsp;&nbsp;Jogging Track
                </span>
              </div>
              <div className="penawaranpoin">
                <FontAwesomeIcon
                  icon={faCheckDouble}
                  color="rgb(200, 169, 92)"
                />
                <span style={{color: "rgb(84, 84, 84)"}}>
                  &nbsp;&nbsp;Tennis Court
                </span>
              </div>
              <div className="penawaranpoin">
                <FontAwesomeIcon
                  icon={faCheckDouble}
                  color="rgb(200, 169, 92)"
                />
                <span style={{color: "rgb(84, 84, 84)"}}>
                  &nbsp;&nbsp;Basketball Court
                </span>
              </div>
              <div className="penawaranpoin">
                <FontAwesomeIcon
                  icon={faCheckDouble}
                  color="rgb(200, 169, 92)"
                />
                <span style={{color: "rgb(84, 84, 84)"}}>
                  &nbsp;&nbsp;Children Playground
                </span>
              </div>
              <div className="penawaranpoin">
                <FontAwesomeIcon
                  icon={faCheckDouble}
                  color="rgb(200, 169, 92)"
                />
                <span style={{color: "rgb(84, 84, 84)"}}>&nbsp;&nbsp;Gym</span>
              </div>
              <div className="penawaranpoin">
                <FontAwesomeIcon
                  icon={faCheckDouble}
                  color="rgb(200, 169, 92)"
                />
                <span style={{color: "rgb(84, 84, 84)"}}>
                  &nbsp;&nbsp;Swimming Pool
                </span>
              </div>
              <div className="penawaranpoin">
                <FontAwesomeIcon
                  icon={faCheckDouble}
                  color="rgb(200, 169, 92)"
                />
                <span style={{color: "rgb(84, 84, 84)"}}>
                  &nbsp;&nbsp;Meeting Room
                </span>
              </div>
            </div>
            <div className="fasilitas2">
              <div className="penawaranpoin">
                <FontAwesomeIcon
                  icon={faCheckDouble}
                  color="rgb(200, 169, 92)"
                />
                <span style={{color: "rgb(84, 84, 84)"}}>
                  &nbsp;&nbsp;Game room
                </span>
              </div>
              <div className="penawaranpoin">
                <FontAwesomeIcon
                  icon={faCheckDouble}
                  color="rgb(200, 169, 92)"
                />
                <span style={{color: "rgb(84, 84, 84)"}}>
                  &nbsp;&nbsp;Theatre Room
                </span>
              </div>
              <div className="penawaranpoin">
                <FontAwesomeIcon
                  icon={faCheckDouble}
                  color="rgb(200, 169, 92)"
                />
                <span style={{color: "rgb(84, 84, 84)"}}>
                  &nbsp;&nbsp;Dining Room
                </span>
              </div>
              <div className="penawaranpoin">
                <FontAwesomeIcon
                  icon={faCheckDouble}
                  color="rgb(200, 169, 92)"
                />
                <span style={{color: "rgb(84, 84, 84)"}}>
                  &nbsp;&nbsp;Mini Golf Course
                </span>
              </div>
              <div className="penawaranpoin">
                <FontAwesomeIcon
                  icon={faCheckDouble}
                  color="rgb(200, 169, 92)"
                />
                <span style={{color: "rgb(84, 84, 84)"}}>
                  &nbsp;&nbsp;Yoga Sanctuary
                </span>
              </div>
              <div className="penawaranpoin">
                <FontAwesomeIcon
                  icon={faCheckDouble}
                  color="rgb(200, 169, 92)"
                />
                <span style={{color: "rgb(84, 84, 84)"}}>
                  &nbsp;&nbsp;Terrace Garden
                </span>
              </div>
              <div className="penawaranpoin">
                <FontAwesomeIcon
                  icon={faCheckDouble}
                  color="rgb(200, 169, 92)"
                />
                <span style={{color: "rgb(84, 84, 84)"}}>
                  &nbsp;&nbsp;Spa & Sauna Lounge
                </span>
              </div>
            </div>
          </div>
          <div className="button">
            <div className="container-wa">
              <button
                className="buttonpenawaran"
                onClick={handleConsultationClick}>
                Learn More
              </button>
            </div>
          </div>
        </div>
        <div className="slider-fasilitas">
          <Slider {...settings1}>
            {Fasilitasimages.map((image, index) => (
              <img className="imagecard" key={index} src={image} />
            ))}
          </Slider>
        </div>
      </div>
    </div>
  );
};

export default About;
